import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import "./BlogAccount.scss"
const  DeviceList= (props) => {

  const[deviceList, setDeviceList]=useState([]);
   const hostName="/Back-end/getDevice";
  const auth=JSON.parse(sessionStorage.getItem('userData'));
  useEffect(() => {
  
    axios
      .get(
        `${hostName}/${auth["user_id"]}`
      )
      .then((reponse) => {
        setDeviceList(reponse.data);
      });
  }, []);

    return(
        <>
    <div className='device'>
        {(deviceList.length===0) ?
        <div className="fa-3x flexAdd">
        <i className="fa-solid fa-circle-plus fa-beat"></i><Link to={`/${props.lang}/MyDevice`} className="linkActive"> {props.textActivate} </Link>
        </div> :
        deviceList.map(d=>{
          return (
              <div >
              <p  style={{padding:"0 15px"}}>  <img src="https://mad-up.com/api/uploads/mad-up/originals/mad-upPRo.svg" alt="mad-up pro" className="imgpro"/>  {d.name} : {d.serial_num}</p>
              <hr/>
              </div>
          )
      }) 
        }
        
      <Link to={`/${props.lang}/MyDevice`}>{props.textDevice} </Link>



</div>      
 
</>
)}
export default DeviceList;