import React , { useState, useEffect }from 'react'
import { Navigate, useParams} from "react-router-dom";
import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataFavoris from "../../Data/Devices.json";
import "./Devices.scss";
import Footer from '../../Components/Footer'
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import Breadcrumb from '../../Components/Breadcrumb';
import SideBar from '../../Components/SideBar/SideBar';
import DevicesList from "./DevicesList"
import AddNewDevice from '../../Components/AddNewDevice/AddNewDevice'

import { date } from 'yup';

 const Devices= (props) => {
  const {lang} = useParams();
  const [data, setData] = useState(dataFavoris.default[lang]);
  const [meta, setMeta] = useState({
      title: dataFavoris.default[lang].meta.title,
      description: dataFavoris.default[lang].meta.description,
      canonical: dataFavoris.default[lang].meta.canonical,
      image: dataFavoris.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataFavoris.default[lang].meta.keywords,
          }

      },
  });
  
  useEffect(() => {
      setData(dataFavoris.default[lang]);
      setMeta({
          title: dataFavoris.default[lang].meta.title,
          description: dataFavoris.default[lang].meta.description,
          canonical: dataFavoris.default[lang].meta.canonical,
          keywords: dataFavoris.default[lang].meta.keywords,
          image: dataFavoris.default[lang].meta.image
      });
  }, [lang]);

  const[auth,setAuth]=useState([]);
  useEffect(()=>{
      
      const authUser=JSON.parse(sessionStorage.getItem('userData'));
      setAuth(authUser);
  },[])

  if(auth===null){
    return <Navigate to={`/${lang}/connexion`} />;
  }
  
   

    
  return (
    <>
     
    <Meta meta={meta} />
    <HeaderAccount />

      <Breadcrumb title={data.breadcrumb} />
        <SideBar>
        <AddNewDevice 
          numSerial={data.form.labelNumSerial}
          text={data.form.ButtonText1} 
          title={data.form.title} 
          ButtonText1={data.form.ButtonText1}
          textOblig={data.form.textOblig}
          text1={data.error.text1}
          errorSerialNumber={data.error.errorSerialNumber}
          numCount={data.error.numCount}
          errorBsn={data.error.errorBsn}
          errorAsn={data.error.errorAsn}
        />
        
        <section className='gridAccount'>
          <DevicesList textDevice={data.textDevice} lang={lang}/>
       </section>
        </SideBar> 
   <Footer/>
    </>
  )
}

export default Devices;