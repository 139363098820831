import React,{useState, useEffect} from 'react';
import { withRouter,Redirect, Link, useParams} from "react-router-dom";
import './GetDevice.scss';
import axios from 'axios';
import * as dataDevice from "../../Data/MyDevice.json";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import ReactLoading from 'react-loading';

export const GetDevice = (props) => {
  const { lang } = useParams();
  const [devices, setDevices] = useState([]);
  const [versionPhysio, setVersionPhysio] = useState({});
  const [versionPro, setVersionPro] = useState({});
  const [loading, setLoading] = useState(true);

  let auth = JSON.parse(sessionStorage.getItem('userData'));

  const user_id = auth.user_id;
  const hostName = "/Back-end/getDevice";

  useEffect(() => {
    // Fonction d'exécution des requêtes
    const executerRequetes = async () => {
      try {
        // Requête pour obtenir les devices
        const deviceResponse = await axios.get(`${hostName}/${user_id}`);
        const devices = deviceResponse.data;

        console.log(deviceResponse);

        // Requêtes pour obtenir les versions
        const versionPhysioResponse = await axios.get('/Back-end/getVersion/PHYSIO');
        const versionProResponse = await axios.get('/Back-end/getVersion/PRO');

        // Mise à jour des états locaux
        setDevices(devices);
        setVersionPhysio(versionPhysioResponse.data);
        setVersionPro(versionProResponse.data);

        setLoading(false); // Désactiver le chargement
      } catch (error) {
        console.error('Erreur lors des requêtes:', error);
      }
    };

    // Exécuter les requêtes une seule fois au chargement du composant
    executerRequetes();
  }, [user_id]); // Dépendance sur user_id pour n'exécuter qu'une fois

  const handleDelete = async (dev_id) => {
    try {
      // Supprimer l'appareil
      const response = await axios.post('/Back-end/RequestdeleteDevice', { id: dev_id });
      console.log(response);
      // Mettre à jour l'état local en filtrant l'appareil supprimé
      setDevices(devices.filter((device) => device.dev_id !== dev_id));
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'appareil:', error);
    }
  };

  if (loading) {
    return <ReactLoading type="bars" color="#15acf2" height={667} width={375} />;
  }

  return (
    <>
      {
        devices.map((device) => {
        let upToDate = false;
        
        if(device.name === "PHYSIO") {
          upToDate = versionPhysio.ihm === device.interface && versionPhysio.core === device.core;

          return (
            <Device
              lang={lang}
              key={device.dev_id}
              device={device}
              upToDate={upToDate}
              handleDelete={handleDelete}
              />
          );
        }
        if(device.name === "PRO") {
          upToDate = true;
          return (
            <DevicePro
              lang={lang}
              key={device.dev_id}
              device={device}
              upToDate={upToDate}
              handleDelete={handleDelete}
              />
          );
        }

        return null;
      })}
    </>

    )
};

const Device=({lang, device, upToDate, handleDelete})=>{
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const [data, setData] = useState(dataDevice.default[lang]);

  const goToolBox = () => {
    sessionStorage.setItem('deviceID',JSON.stringify(device.dev_id))
    window.location.href=`/${lang}/ToolboxPhysio`
  }

  return (
    <section className='displayDevice'>
      <div className="imgDev">
        <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispoPhysio.png" alt="Device" />
      </div>
      <div className='content'>
        <p className='pinfo'>{device.name}</p>
        <hr />
        <p className='pinfo'>{data.device.numSerial} {device.serial_num}</p>
        <hr />
        <p className='pinfo'>{data.device.codeAct} {device.code}</p>
        <hr />
        <p className='pinfo'>
          <div className="buttonExtended color" onClick={goToolBox}>
            <span className="hyphen"></span>
            <div className="buttonExtended_text ligne-texte-icone">
              <span className="texte">{data.device.info}</span>
              {!upToDate && (
                <i className="fa-regular fa-2x fa-bell squizz"></i>
              )}
            </div>
          </div>
        </p>
        <hr />
      </div>
      <div className="imgTrash">
        <i className="fa-regular fa-2x fa-trash-can" onClick={toggleShow}></i>
      </div>

      {/* Modal de confirmation de suppression */}
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader style={{ border: "none" }}>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{ textAlign: "center" }}>
            {data.device.msg} {device.dev_id}
            </MDBModalBody>
            <MDBModalFooter style={{ border: "none" }}>
              <MDBBtn style={{ background: "#DC3535" }} onClick={toggleShow}>{data.device.close}</MDBBtn>
              <MDBBtn style={{ background: "#15acf2" }} onClick={() => handleDelete(device.dev_id)}>{data.device.reponse}</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </section>
  );
};

const DevicePro=({lang, device, upToDate, handleDelete})=>{
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const [data, setData] = useState(dataDevice.default[lang]);

  const goToolBox = () => {
    sessionStorage.setItem('deviceID',JSON.stringify(device.dev_id))
    window.location.href=`/${lang}/ToolboxPro`
  }

  return (
    <section className='displayDevice'>
      <div className="imgDev">
        <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispo.png" alt="Device" />
      </div>
      <div className='content'>
        <p className='pinfo'>{device.name}</p>
        <hr />
        <p className='pinfo'>{data.device.numSerial} {device.serial_num}</p>
        <hr />
        <p className='pinfo'>{data.device.codeAct} {device.code}</p>
        <hr />
        <p className='pinfo'>
          <div className="buttonExtended color" onClick={goToolBox}>
            <span className="hyphen"></span>
            <div className="buttonExtended_text ligne-texte-icone">
              <span className="texte">{data.device.info}</span>
              {!upToDate && (
                <i className="fa-regular fa-2x fa-bell squizz"></i>
              )}
            </div>
          </div>
        </p>
        <hr />
      </div>
      <div className="imgTrash">
        <i className="fa-regular fa-2x fa-trash-can" onClick={toggleShow}></i>
      </div>

      {/* Modal de confirmation de suppression */}
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader style={{ border: "none" }}>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{ textAlign: "center" }}>
            {data.device.msg}
            </MDBModalBody>
            <MDBModalFooter style={{ border: "none" }}>
              <MDBBtn style={{ background: "#DC3535" }} onClick={toggleShow}>{data.device.close}</MDBBtn>
              <MDBBtn style={{ background: "#15acf2" }} onClick={() => handleDelete(device.dev_id)}>{data.device.reponse}</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </section>
  );
}

export default GetDevice;