import React,{useState, useEffect} from 'react';
import { withRouter,Redirect, useParams, Link} from "react-router-dom";
import './AddUser.scss';
import axios from 'axios';
import * as Yup from 'yup';
import { lazy } from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as dataJSON from "../../Data/CreateAccount.json";
import { BiMessageAltError } from "react-icons/bi";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon
} from 'mdb-react-ui-kit'

export const AddUser = () => {
  const { lang } = useParams();
  const [basicModal, setBasicModal] = useState(false);
  const [data, setData] = useState(dataJSON.default[lang]);

  const toggleShow = () => {
    setBasicModal(!basicModal);
  };
  
  const hostNameCompany="/Back-end/getCompanyInfo";
  const hostNameContact="/Back-end/getContactInfo";
  const hostNameAddContact="/Back-end/addUserFromSellsy";
  const handleSubmit = (userData) => {
    console.log(userData.id);
    const companyData = {
      infos: {
        id: userData.id,
        email: userData.email,
        last_name: userData.last_name,
        first_name: userData.first_name,
        phone_number: userData.phone,
        country: "",
        address: "",
        company_name: userData.company_name,
        postal_code: "",
        town: "",
        effectif: userData.effectif,
        role: "Kinésithérapeuthe",
        num_cabinet: userData.company_phone,
        civility: "Mr"
      },
      mail: data.mail,
      type: "achat"
    };
    console.log(companyData);
    axios.post(hostNameAddContact, companyData)
      .then((response) => {
        if (response.data.status === false) {
          // setStatusData(false);
          document.querySelector(".error").innerHTML = data.error.messageError;
          document.querySelector(".error").classList.add("reponseError");
        } else {
          // setStatusData(true);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
        // setStatusData(false);
      });
  };

  const [initialDatas, setInitialDatas] = useState({
    id: "",
    company_name: "",
    company_phone: "",
    effectif: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: ""
  });

  const fillUserData = (searchData) => {
    const companyData = {id: searchData.company_id};
    axios.post(hostNameCompany,companyData)
    .then((response)=>{
      console.log(response.data.company);
      if(response.data.status === false)
      {
        console.log("error");
      }
      else
      {      
        const contactData = {id: response.data.company.main_contact_id.toString()};

        axios.post(hostNameContact, contactData)
        .then((responseContact)=>{
          console.log(responseContact.data);
          if(responseContact.data.status === false)
          {
              console.log("error");
          }
          else
          {
            setInitialDatas({
              id: response.data.company.main_contact_id.toString(),
              company_name: response.data.company.name,
              effectif: response.data.company.number_of_employees,
              company_phone: response.data.company.phone_number || response.data.company.mobile_number,
              first_name: responseContact.data.contact.first_name,
              last_name: responseContact.data.contact.last_name,
              phone: responseContact.data.contact.phone_number || responseContact.data.contact.mobile_number,
              email: responseContact.data.contact.email
            });
          }
        }).catch(error=>{
          console.log(error)
        })
      }
    }).catch(error=>{
      console.log(error)
    })
  }

  const validationSchema = Yup.object().shape({
    company_id: Yup.string().matches(/^[0-9]{8}/,data.error.companyIdError)
                        .required(data.error.errorForm)
    });

  const validationForm = Yup.object().shape({
    company_name: Yup.string().required(data.error.errorForm),
    effectif: Yup.string().required(data.error.errorForm),
    company_phone: Yup.string().required(data.error.errorForm),
    first_name: Yup.string().required(data.error.errorForm),
    last_name: Yup.string().required(data.error.errorForm),
    email: Yup.string().required(data.error.errorForm),
    phone: Yup.string().required(data.error.errorForm)
    });

  const initialValues = {  
    company_id: "48756204"
  };

  return (
    <>
    <MDBIcon onClick={toggleShow} fas icon="plus-circle"  style={{ color: '#15acf2', fontSize: '3rem', margin:"5px" }}/>
    <div className='modalAdd'>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog  style={{margin:"200px auto auto",maxWidth:1000}}>
          <MDBModalContent style={{borderRadius:"25px", border:"1px solid #15acf2",paddingLeft:"10px", fontWeight:"bold"}}>
            <MDBModalHeader style={{border:"none", paddingBottom:"0px"}}>
              <MDBModalTitle style={{textTransform:"uppercase" , fontWeight:"bold"}}>{data.addForm.title}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <hr />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(userData)=>{
                  fillUserData(userData);
                }}
              >
                {({ }) => (
                  <Form className='formUser'>
                    <label htmlFor="company_id" className="me-2">{data.addForm.CompanyId}</label>
                    <div className="form-group mb-4">
                      <Field
                            type="text"
                            id="company_id"
                            name="company_id"
                            placeholder=""
                          />
                      <button type="submit" style={{background:"#15acf2", border:"none", width:"150px", color:"#fff", padding:"10px", borderRadius:"10px", fontWeight:"bold"}}>{data.addForm.search}</button>
                    </div>
                    <p className='msg'></p>
                  </Form>
                )}
              </Formik>
              <hr />
              <Formik
                initialValues={initialDatas}
                enableReinitialize={true} 
                validationSchema={validationForm}
                onSubmit={(userData)=>{
                  handleSubmit(userData);
                }}
              >
                {({  }) => (
                  <Form className='formUser'>
                    <label htmlFor="id" 
                      id="id"
                      name="id"
                      className="me-2"
                      visibility="hidden"></label>
                    <div className="form-group mb-4">
                      <label htmlFor="company_name" className="me-2">{data.addForm.LabelCompanyName}</label>
                      <Field
                            type="text"
                            id="company_name"
                            name="company_name"
                          />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="effectif" className="me-2">{data.addForm.LabelEffectif}</label>
                      <Field
                            type="text"
                            id="effectif"
                            name="effectif"
                          />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="company_phone" className="me-2">{data.addForm.LabelNumCabinet}</label>
                      <Field
                            type="text"
                            id="company_phone"
                            name="company_phone"
                          />
                    </div>
                    <hr />
                    <div className="form-group mb-4">
                      <label htmlFor="first_name" className="me-2">{data.addForm.labelFirstName}</label>
                      <Field
                            type="text"
                            id="first_name"
                            name="first_name"
                          />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="last_name" className="me-2">{data.addForm.LabelLastName}</label>
                      <Field
                            type="text"
                            id="last_name"
                            name="last_name"
                          />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="email" className="me-2">{data.addForm.LabelEmail}</label>
                      <Field
                            type="text"
                            id="email"
                            name="email"
                          />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="phone" className="me-2">{data.addForm.LabelPhoneNumber}</label>
                      <Field
                            type="text"
                            id="phone"
                            name="phone"
                          />
                    </div>
                    <MDBModalFooter className="d-flex justify-content-between align-items-start" style={{border:"none", paddingTop:"0px"}}>
                      <button type="submit" style={{background:"#15acf2", border:"none", width:"150px", color:"#fff", padding:"10px", borderRadius:"10px", fontWeight:"bold"}}>{data.addForm.Validate}</button>    
                    </MDBModalFooter>
                    <p className='msg'></p>
                  </Form>
                )}
              </Formik>
            </MDBModalBody>
          </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>      
    </>
  )
};

export default AddUser;
