import React , { useState, useEffect }from 'react'
import { Navigate, useParams} from "react-router-dom";
import Meta from "../../Components/Meta";
import * as dataDevice from "../../Data/MyDevice.json";
import "./MyDevice.scss";
import Footer from '../../Components/Footer'
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import Breadcrumb from '../../Components/Breadcrumb';
import SideBar from '../../Components/SideBar/SideBar';
import AddDevice from '../../Components/AddDevice/AddDevice'
import GetDevice from '../../Components/GetDevice/GetDevice';
import { date } from 'yup';

 const MyDevice= (props) => {
  const {lang} = useParams();
  const [data, setData] = useState(dataDevice.default[lang]);
  const [meta, setMeta] = useState({
      title: dataDevice.default[lang].meta.title,
      description: dataDevice.default[lang].meta.description,
      canonical: dataDevice.default[lang].meta.canonical,
      image: dataDevice.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataDevice.default[lang].meta.keywords,
          }

      },
  });
  
  useEffect(() => {
      setData(dataDevice.default[lang]);
      setMeta({
          title: dataDevice.default[lang].meta.title,
          description: dataDevice.default[lang].meta.description,
          canonical: dataDevice.default[lang].meta.canonical,
          keywords: dataDevice.default[lang].meta.keywords,
          image: dataDevice.default[lang].meta.image
      });
  }, [lang]);

  const[auth,setAuth]=useState([]);
  useEffect(()=>{
      const authUser=JSON.parse(sessionStorage.getItem('userData'));
      setAuth(authUser);
  },[])

  if(auth===null){
    window.location.href = `https://mad-up-academy.com`;
  }

  return (
    <>
     
    <Meta meta={meta} />
   <HeaderAccount />
      <Breadcrumb title={data.breadcrumb} />
        <SideBar>
        <AddDevice 
        date={data.form.labelDate} 
        numSerial={data.form.labelNumSerial}
        text={data.form.ButtonText1} 
        title={data.form.title} 
        labelRevendeur={data.form.labelRevendeur} 
        labelFile={data.form.labelFile}
        ButtonText1={data.form.ButtonText1}
        spanRevendeur={data.form.spanRevendeur}
        spanFile={data.form.spanFile}
        pFile={data.form.pFile}
        textMail={data.form.textMail}
        textOblig={data.form.textOblig}
        buttonFile={data.form.buttonFile}
        text1={data.error.text1}
        inputFile={data.form.inputFile}
        errorSerialNumber={data.error.errorSerialNumber}
        errorDeviceUnknow={data.error.errorDeviceUnknow}
        numCount={data.error.numCount}
        numMin={data.error.numMin}
        numMax={data.error.numMax}
        msg={data.form.msg}
        msgE={data.form.msgE}
        textError={data.form.textError}
        textError2={data.form.textError2}
        errorOldSerialNumber={data.error.errorOldSerialNumber}
        errorNewSerialNumber={data.error.errorNewSerialNumber}
        errorOldnumCount={data.error.errorOldnumCount}
        />
            <GetDevice 
                lang={lang}
            />
        </SideBar> 
   <Footer/>
    </>
  )
}

export default MyDevice;