import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from "react-router-dom";
import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataJson from "../../Data/BlogAccount.json";
import "./BlogAccount.scss"
import axios from 'axios';
import BlogUser from '../../Components/BlogAccountUser/BlogAccountUser';
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import SideBar from '../../Components/SideBar/SideBar';
import RecipeReviewCard from "./BlogAccountCard";
import Breadcrumb from "../../Components/Breadcrumb";
import Pagination from '../../Components/Pagination/Pagination';
import Footer from '../../Components/Footer'
import FAQCard from './FAQCard';
import FAQQuestion from './FAQQuestion';
// import CircularProgress from "./CircularProgress"
import DeviceList from "./DeviceList"
const HomeAccount = (props) => {
  const { lang } = useParams();

  const [data, setData] = useState(dataJson.default[lang]);
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(12);
  const [currentPageFaq, setCurrentPageFaq] = useState(1);
  const [articlesPerPageFaq] = useState(12);
  const [faq, setFaq] = useState([]);
  // const [pourc, setPourc] = useState([]);
  const [cat, setCat] = useState([]);
  const [con, setCon] = useState([]);


  const [meta, setMeta] = useState({
    title: dataJson.default[lang].meta.title,
    description: dataJson.default[lang].meta.description,
    canonical: dataJson.default[lang].meta.canonical,
    image: dataJson.default[lang].meta.image,
    meta: {
      charset: "utf-8",
      name: {
        keywords: dataJson.default[lang].meta.keywords,
      }

    },
  });

  useEffect(() => {
    setData(dataJson.default[lang]);
    setMeta({
      title: dataJson.default[lang].meta.title,
      description: dataJson.default[lang].meta.description,
      canonical: dataJson.default[lang].meta.canonical,
      keywords: dataJson.default[lang].meta.keywords,
      image: dataJson.default[lang].meta.image
    });
  }, [lang]);

  useEffect(() => {
    axios.get(`https://mad-up.com/api/mad-up/items/blog_account?fields=id,cover.*, created_on, modified_by, translations.*&sort=-created_on&meta=result_count,filter_count,total_count&lang=${lang}`)
      .then(reponse => {
        //console.log(reponse)
        setBlogs(reponse.data.data);

      })
  }, [lang])


  const LoadDataFaq = () => {
    axios.get(`https://mad-up.com/api/mad-up/items/faq?fields=id,created_on, translations.*&sort=-created_on&meta=result_count,filter_count,total_count&lang=${lang}`)
      .then(reponse => {
        //console.log(reponse)
        setFaq(reponse.data.data);
        //console.log(reponse.data.data)
      });
  }
  useEffect(() => {
    LoadDataFaq();
  }, [lang])

  // const hostName = "/Back-end/getElearning";
  // useEffect(() => {
  //   getElerningById();
  // }, []);
  // const getElerningById = () => {
  //   axios
  //     .get(
  //       `${hostName}/${auth["user_id"]}`)
  //     .then((reponse) => {
  //       //console.log(reponse.data) 
  //       setPourc(reponse.data)
  //     });
  // }
  useEffect(() => {
    axios.get(`https://mad-up.com/api/mad-up/items/category_video?fields=id,image.*,slug,translation.*,translation.category,translation.langage&lang=${lang}`
    )
      .then(reponse => {
        setCat(reponse.data.data);
        console.log(reponse.data.data)
      })
  }
    , [lang])


  // useEffect(() => {
  //   pourc.map(p => {
  //     return setCon(oldArray => [...oldArray, parseInt(p.pourc)])
  //   })
  // }, [pourc])







  const auth = JSON.parse(sessionStorage.getItem('userData'));
  if (auth === null) {
    return <Navigate to={`/${lang}/connexion`} />;
  }



  //connaitre la page courante de la fil d'actualité 
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = blogs.slice(indexOfFirstArticle, indexOfLastArticle);

  // changement de page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  //connaitre la page courante de la FAQ 
  const indexOfLastArticleFaq = currentPageFaq * articlesPerPage;
  const indexOfFirstArticleFaq = indexOfLastArticleFaq - articlesPerPageFaq;
  const currentArticlesFaq = faq.slice(indexOfFirstArticleFaq, indexOfLastArticleFaq);

  // changement de page
  const paginateFaq = pageNumber => setCurrentPageFaq(pageNumber);
  return (
    <>

      <Meta meta={meta} />
      <HeaderAccount />

      {/* <BigTitle
        text=""
        title={data.bigtitle.title}
        span={data.bigtitle.span}
        direct="left"
    /> */}

      <div>
        <Breadcrumb title={data.breadcrumb} />

        <SideBar>
          <div className='welc'>
            <span className='textWel'> {data.text}, </span>
            <span className='name'>{auth["first_name"]}&nbsp;{auth["last_name"]}</span>
            <p>{data.text2}</p>
          </div>
          <section className='gridAccount'>

            <DeviceList textTitle={data.textTitle} textDevice={data.textDevice} lang={lang} textActivate={data.textActivate} errorDeviceUnknow={data.errorDeviceUnknow} />
            {/* <div className='linkProgress'>
                  <CircularProgress lang={lang} textProgress={data.textProgress}
                  
                  value={con.reduce(function(acc, val) { return (acc + val/ cat.length); }, 0)}
                  
                  />
                  </div> */}
          </section>
          <section className='gridAccount'>
            <div className='FAQ'>
              <BigTitle
                text=""
                title={data.bigtitle1.title}
                span={data.bigtitle1.span}
                direct="left"
              />

              <FAQQuestion textPlaceholder={data.FAQ.textPlaceholder} questionRe={data.FAQ.questionRe} />
              <h2 className='h2FAQ'>{data.FAQ.titleFAQ}</h2>



              {
                currentArticlesFaq &&
                currentArticlesFaq.map((faq) => {
                  return (
                    <div key={faq.id}>

                      <FAQCard question={data.FAQ.question} answer={data.FAQ.answer} date={data.FAQ.date}
                        questionp={faq.translations[0].question}
                        answerp={faq.translations[0].answer}
                        created_on={faq.created_on}
                      />

                    </div>
                  )
                })
              }

              <Pagination
                articlesPerPage={articlesPerPageFaq}
                totalArticles={faq.length}
                paginate={paginateFaq}
                currentPage={currentPageFaq}
              />
            </div>








            <div className='blogCard'>
              <BigTitle
                text=""
                title={data.bigtitle.title}
                span={data.bigtitle.span}
                direct="left"
              />

              {
                currentArticles &&
                currentArticles.map((blog) => {
                  return (
                    <div key={blog.id}>

                      <RecipeReviewCard title={blog.translations[0].title} created_on={blog.created_on} content_short={blog.translations[0].content_short} content_full={blog.translations[0].content_full} full_url={blog.cover.data.full_url} />

                    </div>
                  )
                })
              }
              <Pagination
                articlesPerPage={articlesPerPage}
                totalArticles={blogs.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>

          </section>
        </SideBar>
      </div>
      <Footer />
    </>
  )
}

export default HomeAccount;