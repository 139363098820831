import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumb from '../../Components/Breadcrumb';
import * as dataJSON from "../../Data/Version.json";
import Meta from "../../Components/Meta";
import { useFormik } from "formik";
import axios from 'axios';
import "./Version.scss";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount";
import SideBar from '../../Components/SideBar/SideBar';
import Footer from '../../Components/Footer';
import BigTitle from "../../Components/BigTitle";
import ReactLoading from 'react-loading';

const MAX_RETRIES = 5; // Nombre maximum de tentatives
const INITIAL_DELAY = 1000; // Délai initial en millisecondes (1 seconde)

const fetchDataWithRetry = async (url, retries = MAX_RETRIES, delay = INITIAL_DELAY) => {
  try {
    // Essaye d'exécuter la requête
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(`Erreur lors de la requête (tentative restante: ${retries}):`, error);

    if (retries === 0) {
      // Si on atteint 0 tentatives restantes, on jette l'erreur
      throw new Error('Echec après plusieurs tentatives.');
    }

    // Sinon, on attend un certain temps et on réessaie
    await new Promise(resolve => setTimeout(resolve, delay));

    // Augmenter le délai de manière exponentielle
    return fetchDataWithRetry(url, retries - 1, delay * 2);
  }
};

export const Version = (props) => {
    const { lang } = useParams();
    const [data, setData] = useState(dataJSON.default[lang]);
    const [meta, setMeta] = useState({
        title: data.meta.title,
        description: data.meta.description,
        canonical: data.meta.canonical,
        image: data.meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: data.meta.keywords,
            }
        },
    });

    const [versionPhysio, setVersionPhysio] = useState(null);
    const [versionPro, setVersionPro] = useState(null);
    const [error, setError] = useState(null);

    let auth = JSON.parse(sessionStorage.getItem('userData'));

    const user_id = auth.user_id;

    useEffect(() => {
        const loadVersions = async () => {
          try {
            const physioData = await fetchDataWithRetry('/Back-end/getVersion/PHYSIO');
            const proData = await fetchDataWithRetry('/Back-end/getVersion/PRO');
            
            setVersionPhysio(physioData);
            setVersionPro(proData);
          } catch (err) {
            setError(err.message);
          }
        };
    
        loadVersions();
    }, [user_id]); // Dépendance sur user_id pour n'exécuter qu'une fois

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);

    const notification = {
        sendUpdateNotification(updateInfo, data) {
            axios.post("/Back-end/getMailNotificationUpdate", updateInfo)
            .then((response) => {
                // console.log(response);
                response.data.forEach(device => {
                    const params = {
                        device: device,
                        mail: data.mail
                    };
                    // console.log(params);
                    axios.post("/Back-end/sendNotificationUpdate", params)
                    .then((response) => {
                        // console.log(response.data);
                    })
                    .catch(console.error);
                });

                alert("Envoi des notifications effectuées !");
                window.location.reload();
            }).catch(console.error);
        }
    };

    const handleSubmit = (dataVersion) => {
        axios.post("/Back-end/updateSoftVersion", dataVersion)
            .then((response) => {
                console.log(response.data);
                notification.sendUpdateNotification(dataVersion, data);
            })
            .catch(console.error);
    };

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    if (!versionPhysio || !versionPro) {
        return <ReactLoading type="bars" color="#15acf2" height={667} width={375} />;
    }

    return (
        <>
            <div className='formCreate'>
                <Meta meta={meta} />
                <HeaderAccount />
                <Breadcrumb title={data.breadcrumb} />
                <SideBar>
                    <VersionForm
                        versionPhysio={versionPhysio}
                        versionPro={versionPro}
                        data={data}
                        notification={notification}
                        handleSubmit={handleSubmit}
                    />
                </SideBar>
            </div>
            <Footer />
        </>
    );
};

const VersionForm = ({ versionPhysio, versionPro, data, notification, handleSubmit }) => {

    const mySchema = Yup.object().shape({
        model: Yup.string().required(data.update.errMsg),
        type: Yup.string().required(data.update.errMsg),
        value: Yup.string().required(data.update.errMsg),
        info: Yup.string().required(data.update.errMsg),
    });

    const formik = useFormik({
        initialValues: {
            model: '', // PHYSIO ou PRO
            type: '', // Core ou Interface
            value: '',
            info: ''
        },
        validationSchema: mySchema,
        onSubmit: (values) => {
            const dataVersion = {
                model: values.model.toUpperCase(),
                type: values.type.toUpperCase(),
                value: values.value,
                desc: values.info
            };
            handleSubmit(dataVersion);
        },
        enableReinitialize: true,
    });

    return (
        <div>
            <section className='displayVersion'>
                <BigTitle title={data.info.title} direct="left" />
                <div className="form-group mb-3">
                    <label>{data.info.physio}</label>
                    <div>
                        <label>{data.info.interface} {versionPhysio.ihm}</label>
                    </div>
                    <div>
                        <label>{data.info.core} {versionPhysio.core}</label>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <label>{data.info.pro}</label>
                    <div>
                        <label>{data.info.interface} {versionPro.ihm}</label>
                    </div>
                    <div>
                        <label>{data.info.core} {versionPro.core}</label>
                    </div>
                </div>
            </section>
            <section className='displayVersion'>
                <BigTitle title={data.update.title} direct="left" />

                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-3">
                        <label>{data.update.model}</label>
                        {/* <div> */}
                            <label>
                                <input
                                    type="radio"
                                    name="model"
                                    value="PHYSIO"
                                    checked={formik.values.model === 'PHYSIO'}
                                    onChange={formik.handleChange}
                                />
                                PHYSIO
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="model"
                                    value="PRO"
                                    checked={formik.values.model === 'PRO'}
                                    onChange={formik.handleChange}
                                />
                                PRO
                            </label>
                        {/* </div> */}
                        {formik.errors.model && <div className="error">{formik.errors.model}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label>{data.update.type}</label>
                        {/* <div> */}
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="Core"
                                    checked={formik.values.type === 'Core'}
                                    onChange={formik.handleChange}
                                />
                                Coeur
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="Ihm"
                                    checked={formik.values.type === 'Ihm'}
                                    onChange={formik.handleChange}
                                />
                                Interface
                            </label>
                        {/* </div> */}
                        {formik.errors.type && <div className="error">{formik.errors.type}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label>{data.update.value}</label>
                        <input
                            type="text"
                            name="value"
                            value={formik.values.value}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.errors.value && <div className="error">{formik.errors.value}</div>}
                    </div>

                    <div className="form-group textarea-group mb-3">
                        <label>{data.update.description}</label>
                        <textarea
                            name="info"
                            value={formik.values.info}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.errors.info && <div className="error">{formik.errors.info}</div>}
                    </div>

                    <div className="form-group d-flex justify-content-end gap-3">
                        <button type="submit" className="btn colorBtn" disabled={formik.isSubmitting || !formik.isValid}>
                            {data.update.btnAdd}
                        </button>
                    </div>
                </form>
            </section>
        </div>
    );
};



// const searchDeviceHostname = "/Back-end/getMailNotificationUpdate";
// const mailHostname = "/Back-end/sendNotificationUpdate";

// const notification = {
//     sendUpdateNotification(updateInfo, data) {
//         console.log(updateInfo)
//         axios.post(searchDeviceHostname, updateInfo).then((response) => {
//             console.log(response.data)
//             response.data.forEach(device => {
//                 console.log(device.email)

//                 const params = {
//                     email: device.email,
//                     sn: device.serial_num,
//                     name: device.first_name,
//                     model: device.name,
//                     subject: data.mail.subject,
//                     wellcome: data.mail.wellcome,
//                     msgStart: data.mail.msgStart,
//                     msgSN: data.mail.msgSN,
//                     msgEnd: data.mail.msgEnd,
//                     details: data.mail.details,
//                     msgLink: data.mail.msgLink,
//                     sign: data.mail.sign,
//                     msgIgnore: data.mail.msgIgnore
//                 }
//                 axios.post(mailHostname, params).then((response) => {
//                 }).catch(error => {
//                     console.log(error)
//                 })

//                 window.location.reload(false)
//             });
//         }).catch(error => {
//             console.log(error)
//         })
//     }
// }

// const hostName = "/Back-end/getVersion";
// const loadProData = () => {
//     console.log(versionPro.model);
//     axios
//         .get(
//             `${hostName}/PRO`
//         )
//         .then((reponse) => {
//             setVersionPro(reponse.data);
//             console.log(reponse.data)
//         });
// }
// const loadPhysioData = () => {
//     console.log(versionPhysio.model);
//     axios
//         .get(
//             `${hostName}/PHYSIO`
//         )
//         .then((reponse) => {
//             setVersionPhysio(reponse.data);
//             console.log(reponse.data)
//         });
// }

// useEffect(() => { loadProData() }, []);
// useEffect(() => { loadPhysioData() }, []);

//     return (
//         <>
//             <div className='formCreate'>
//                 <Meta meta={meta} />
//                 <HeaderAccount />
//                 <Breadcrumb title={data.breadcrumb} />
//                 <SideBar>
//                     <VersionPhysio
//                         props={data}
//                         lang={lang}
//                         devModel={versionPhysio.model}
//                         devIhm={versionPhysio.ihm}
//                         devCore={versionPhysio.core}
//                         title={data.title.physio}
//                         titleIhm={data.title.interface}
//                         titleCore={data.title.core}
//                         buttonSave={data.title.buttonSave}
//                         notification={notification}
//                     />

//                     <VersionPro
//                         props={data}
//                         lang={lang}
//                         devModel={versionPro.model}
//                         devIhm={versionPro.ihm}
//                         devCore={versionPro.core}
//                         title={data.title.pro}
//                         titleIhm={data.title.interface}
//                         titleCore={data.title.core}
//                         buttonSave={data.title.buttonSave}
//                         notification={notification}
//                     />
//                 </SideBar>

//             </div>
//             <Footer />
//         </>
//     )
// };

// const VersionPhysio = ({ props, title, devIhm, devCore, devModel, titleIhm, titleCore, buttonSave, notification }) => {
//     const hostName = "/Back-end/updateSoftVersion";
//     const handleSubmit = (dataVersion) => {
//         axios.post(hostName, dataVersion).then((response) => {
//             window.location.reload(false)
//             notification.sendUpdateNotification(dataVersion, props)
//         }).catch(error => {
//             console.log(error)
//         })
//     };

//     const mySchema = Yup.object().shape({
//         ihm: Yup.string()
//             .required("Ce champ est obligatoire !")
//             .test('equal', 'identique à la valeur initiale', function (newValue) {
//                 let core = this.parent.core;
//                 if (newValue === devIhm && core === devCore)
//                     return false;
//                 return true;
//             }),
//         core: Yup.string()
//             .required("Ce champ est obligatoire !")
//             .test('equal', 'identique à la valeur initiale', function (newValue) {
//                 let ihm = this.parent.ihm;
//                 if (newValue === devCore && ihm === devIhm)
//                     return false;
//                 return true;
//             }),
//     });

//     const formik = useFormik({
//         initialValues: {
//             model: devModel,
//             ihm: devIhm,
//             core: devCore
//         },
//         validationSchema: mySchema,
//         onSubmit: (values) => {
//             handleSubmit(values);
//         },
//         enableReinitialize: true,
//     });

//     return (
//         <section className='displayVersion'>
//             <BigTitle
//                 text=""
//                 title={title}
//                 small=""
//                 direct="left"
//             />
//             <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispoPhysio.png" alt="Device" />
//             <form onSubmit={formik.handleSubmit}>
//                 <div className="form-group mb-3">
//                     <label>
//                         {titleIhm}
//                     </label>

//                     <input
//                         type="text"
//                         id="ihm"
//                         name="ihm"
//                         size="10"
//                         value={formik.values.ihm}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                     />
//                 </div>
//                 <div className="form-group mb-3">
//                     <label htmlFor="core" >
//                         {titleCore}
//                     </label>

//                     <input
//                         type="text"
//                         id="core"
//                         name="core"
//                         size="10"
//                         value={formik.values.core}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                     />
//                 </div>
//                 <div className="form-group d-flex justify-content-end gap-3">
//                     <button type="submit" className="btn colorBtn" disabled={formik.isSubmitting || !formik.isValid}>
//                         {buttonSave}
//                     </button>
//                 </div>
//             </form>
//         </section>
//     )
// }

// const VersionPro = ({ props, title, devIhm, devCore, devModel, titleIhm, titleCore, buttonSave, notification }) => {
//     const hostName = "/Back-end/updateSoftVersion";
//     const handleSubmit = (dataVersion) => {
//         axios.post(hostName, dataVersion).then((response) => {
//             window.location.reload(false)
//             notification.sendUpdateNotification(dataVersion, props)
//         }).catch(error => {
//             console.log(error)
//         })
//     };

//     const mySchema = Yup.object().shape({
//         ihm: Yup.string()
//             .required("Ce champ est obligatoire !")
//             .test('equal', 'identique à la valeur initiale', function (newValue) {
//                 let core = this.parent.core;
//                 if (newValue === devIhm && core === devCore)
//                     return false;
//                 return true;
//             }),
//         core: Yup.string()
//             .required("Ce champ est obligatoire !")
//             .test('equal', 'identique à la valeur initiale', function (newValue) {
//                 let ihm = this.parent.ihm;
//                 if (newValue === devCore && ihm === devIhm)
//                     return false;
//                 return true;
//             }),
//     });

//     const formik = useFormik({
//         initialValues: {
//             model: devModel,
//             ihm: devIhm,
//             core: devCore
//         },
//         validationSchema: mySchema,
//         onSubmit: (values) => {
//             handleSubmit(values);
//         },
//         enableReinitialize: true,
//     });

//     return (
//         <section className='displayVersion'>
//             <BigTitle
//                 text=""
//                 title={title}
//                 small=""
//                 direct="left"
//             />
//             <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispo.png" alt="Device" />
//             <form onSubmit={formik.handleSubmit}>
//                 <div className="form-group mb-3">
//                     <label>
//                         {titleIhm}
//                     </label>

//                     <input
//                         type="text"
//                         id="ihm"
//                         name="ihm"
//                         size="10"
//                         value={formik.values.ihm}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                     />
//                 </div>
//                 <div className="form-group mb-3">
//                     <label htmlFor="core" >
//                         {titleCore}
//                     </label>

//                     <input
//                         type="text"
//                         id="core"
//                         name="core"
//                         size="10"
//                         value={formik.values.core}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                     />
//                 </div>
//                 <div className="form-group d-flex justify-content-end gap-3">
//                     <button type="submit" className="btn colorBtn" disabled={formik.isSubmitting || !formik.isValid}>
//                         {buttonSave}
//                     </button>
//                 </div>
//             </form>
//         </section>
//     )
// }

export default Version