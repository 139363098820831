import React , { useState, useEffect }from 'react'
import { Navigate, useParams} from "react-router-dom";
import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataFavoris from "../../Data/Users.json";
import "./Users.scss";
import Footer from '../../Components/Footer'
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import Breadcrumb from '../../Components/Breadcrumb';
import SideBar from '../../Components/SideBar/SideBar';
import UserList from "./UserList"
import AddUser from '../../Components/AddUser/AddUser'

import { date } from 'yup';

 const Users= (props) => {
  const {lang} = useParams();
  const [data, setData] = useState(dataFavoris.default[lang]);
  const [meta, setMeta] = useState({
      title: dataFavoris.default[lang].meta.title,
      description: dataFavoris.default[lang].meta.description,
      canonical: dataFavoris.default[lang].meta.canonical,
      image: dataFavoris.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataFavoris.default[lang].meta.keywords,
          }

      },
  });
  
  useEffect(() => {
      setData(dataFavoris.default[lang]);
      setMeta({
          title: dataFavoris.default[lang].meta.title,
          description: dataFavoris.default[lang].meta.description,
          canonical: dataFavoris.default[lang].meta.canonical,
          keywords: dataFavoris.default[lang].meta.keywords,
          image: dataFavoris.default[lang].meta.image
      });
  }, [lang]);

  const[auth,setAuth]=useState([]);
  useEffect(()=>{
      
      const authUser=JSON.parse(sessionStorage.getItem('userData'));
      setAuth(authUser);
  },[])

  if(auth===null){
    return <Navigate to={`/${lang}/connexion`} />;
  }
  
   

    
  return (
    <>
     
    <Meta meta={meta} />
    <HeaderAccount />
      <Breadcrumb title={data.breadcrumb} />
        <SideBar>
        <AddUser 
          
        />
        <section className='gridAccount'>
          <UserList textUser={data.textUser} lang={lang}/>
       </section>
        </SideBar> 
   <Footer/>
    </>
  )
}

export default Users;